<template>
  <portlet :color="criticality.color">
    <template slot="title">
      {{ risk.position }} -
      {{ risk.character !== null ? risk.character.label : '' }}
      (Criticité {{ criticality.quotation }} : {{ criticality.value }})
    </template>
    <template slot="actions">
      <slot name="actions"></slot>
    </template>

    <div class="row" v-if="selectMode">
      <div class="col-md-5">
        <portlet color="light-grey" title="Affectations">
          <ul v-if="risk.structures.length > 0">
            <li v-for="(structure, key) in risk.structures" :key="key">
              {{ structure.path }}
            </li>
          </ul>
          <p v-else> Aucune affectation </p>
        </portlet>
      </div>
      <div class="col-md-7" style="white-space: pre-wrap">
        description : <br> {{risk.description}}
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-3">
        <portlet color="light-grey" title="Affectations">
          <ul v-if="risk.structures.length > 0">
            <li v-for="(structure, key) in risk.structures" :key="key">
              {{ structure.path }}
            </li>
          </ul>
          <p v-else> Aucune affectation </p>
        </portlet>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-4">
            <el-select v-if="editable" v-model="editFrequency" placeholder="Fréquence">
              <el-option
                v-for="[key, value] in frequencies"
                :key="key"
                :label="value"
                :value="key">
              </el-option>
            </el-select>
            <span v-else>{{ frequencies.get(risk.frequency) || 'n/c' }}</span>
          </div>
          <div class="col-md-4">
            <el-select v-if="editable" v-model="editSeriousness" placeholder="Gravité">
              <el-option
                v-for="[key, value] in seriousness"
                :key="key"
                :label="value"
                :value="key">
              </el-option>
            </el-select>
            <span v-else>{{ seriousness.get(risk.seriousness) || 'n/c' }}</span>
          </div>
          <div class="col-md-4">
            <el-select v-if="editable" v-model="editMastery" placeholder="Maîtrise">
              <el-option
                v-for="[key, value] in masters"
                :key="key"
                :label="value"
                :value="key">
              </el-option>
            </el-select>
            <span v-else>{{ masters.get(risk.mastery) || 'n/c' }}</span>
          </div>
        </div>
        <div class="row" v-if="editable">
          <div class="col-md-12 information-quotation-col"
               v-if="risk.frequency === null || risk.seriousness === null || risk.mastery === null">
            <div class="information-quotation" style="display: block;">
              <font-awesome-icon icon="exclamation"/>
              <i>Veuillez saisir les trois critères pour avoir un aperçu de la criticité.</i>
            </div>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-12 risque-quotation-desc">
            <p>
              {{ risk.description }}
            </p>
          </div>
          <div class="col-md-12">
            <p class="bold accordion"
               @click="showOrganizationalArrangements = !showOrganizationalArrangements">
              Prévention organisationnelle existante</p>
            <transition name="slideCotation">
              <p v-if="showOrganizationalArrangements" class="accordion-target"
                 v-html="organizationalArrangements">
              </p>
            </transition>
            <p class="bold accordion"
               @click="showCollectiveArrangements = !showCollectiveArrangements">
              Prévention collective existante
            </p>
            <transition name="slideCotation">
              <p v-if="showCollectiveArrangements" class="accordion-target"
                 v-html="collectiveArrangements">
              </p>
            </transition>
            <p class="bold accordion"
               @click="showIndividualArrangements = !showIndividualArrangements">
              Prévention individuelle existante
            </p>
            <transition name="slideCotation">
              <p v-if="showIndividualArrangements" class="accordion-target"
                 v-html="individualArrangements">
              </p>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </portlet>
</template>

<script lang="ts">
import _ from 'lodash';
import {
  Vue, Component, Prop, Watch, PropSync,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { lazyInject } from '@/ioc/container';
import { FormComponent } from '@/types/v-form';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import { Criticality, CRITICALITY_UNDEFINED } from '@modules/professional-risk/types/risk';
import {
  FrequenciesEnumName,
  MastersEnumName,
  SeriousnessEnumName,
} from '@modules/professional-risk/types/enum';

import Risk from '@models/proRisk/Risk';

const riskProRepository = namespace('repositories/risk');

@Component
export default class ProRiskQuotationCard extends Vue implements FormComponent {
  @Prop({})
  value!: Risk;

  @Prop({ type: Boolean, default: false })
  selectMode!: boolean;

  @PropSync('color')
  colorSync!: string;

  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  @riskProRepository.Action('updateQuotation')
  save!: (risk: Risk) => Promise<void>;

  risk: Risk | null = null;
  riskColor: Risk | null = null;

  showOrganizationalArrangements: boolean = false;
  showIndividualArrangements: boolean = false;
  showCollectiveArrangements: boolean = false;

  frequencies = FrequenciesEnumName;
  seriousness = SeriousnessEnumName;
  masters = MastersEnumName;

  editFrequency: number | null = null;
  editSeriousness: number | null = null;
  editMastery: number | null = null;

  get criticality(): Criticality {
    this.riskColor = _.cloneDeep(this.risk);
    if (this.riskColor) {
      this.riskColor.frequency = this.editFrequency;
      this.riskColor.seriousness = this.editSeriousness;
      this.riskColor.mastery = this.editMastery;
    }
    return this.riskColor ? this.riskService.criticality(this.riskColor) : CRITICALITY_UNDEFINED;
  }

  get organizationalArrangements() {
    return this.risk && this.risk.organizationalArrangements ? this.risk.organizationalArrangements : '<i>(Non renseigné)</i>';
  }

  get collectiveArrangements() {
    return this.risk && this.risk.collectiveArrangements ? this.risk.collectiveArrangements : '<i>(Non renseigné)</i>';
  }

  get individualArrangements() {
    return this.risk && this.risk.individualArrangements ? this.risk.individualArrangements : '<i>(Non renseigné)</i>';
  }

  get isModify() {
    return this.risk !== null && (this.editFrequency !== this.value.frequency
      || this.editSeriousness !== this.value.seriousness
      || this.editMastery !== this.value.mastery);
  }

  @Watch('value', { immediate: true })
  onValueChange() {
    this.risk = _.cloneDeep(this.value);

    this.editFrequency = this.editFrequency === null ? this.risk.frequency : this.editFrequency;
    this.editSeriousness = this.editSeriousness === null ? this.risk.seriousness : this.editSeriousness;
    this.editMastery = this.editMastery === null ? this.risk.mastery : this.editMastery;
  }

  @Watch('criticality')
  onCriticalityChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editFrequency')
  onEditFrequencyChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editSeriousness')
  onEditSeriousnessChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editMastery')
  onEditMasterChange() {
    this.colorSync = this.criticality.color;
  }

  async valid(): Promise<void> {
    if (this.risk !== null && this.isModify) {
      this.risk.frequency = this.editFrequency;
      this.risk.seriousness = this.editSeriousness;
      this.risk.mastery = this.editMastery;
      return this.save(this.risk);
    }

    return Promise.resolve();
  }
}
</script>

<style lang="css" scoped>
  .row .col-md-12 p{
    white-space: pre-wrap;
  }
</style>
